<template>
    <div class="menu-tab">
        <a-tabs v-model:activeKey="active" @change="changeTab">
            <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tab">
                <slot></slot>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>
<script>
import { ref } from 'vue'
export default {
    name:'com-menu-tab',
    props:{
        tabs:{  // [{tab:'菜单名称',key:'menu-tab-key'}]
            type:Array,
            default:[]
        }, 
    },
    setup(props,context) {
        const active = ref()
        //获取缓存中的tab信息 保证页面刷新也在刷新前的tab位置
        if( localStorage.getItem("_KD_MENU_TAB_")){
            active.value = localStorage.getItem("_KD_MENU_TAB_")
            let keys = []
            props.tabs.forEach(item=>{
                keys.push(item.key)
            })
            if( !keys.includes(active.value) ){
                active.value = props.tabs[0].key
            }
        }else{
            if( props.tabs && props.tabs.length > 0 ){
                active.value = props.tabs[0].key
                localStorage.setItem("_KD_MENU_TAB_",active.value)
            }
        }

        const changeTab = (e)=>{
            localStorage.setItem("_KD_MENU_TAB_",e)
            console.log('e11',e);
            
            context.emit("change",e)
            context.emit("changeTab",e)
        }

        return{
            active,
            changeTab
        }
    },
}
</script>