<template>
    <div class="el-content">
        <com-menu-tab :tabs="tabs" @changeTab="e=>active = e">
            <!-- 基本设置 -->
            <div v-if="active =='shop_set_basic'">
                <a-form :label-col="{span:3}" :wrapper-col="{span:8}">
                    <a-form-item label="商城列表页样式">
                        <a-radio-group v-model:value="form.shop_style">
                            <a-radio value="1">样式一(默认样式)
                                <a-tooltip>
                                    <template #title>
                                        <div><img src="@/assets/img/shop-style-1.png" alt="" ></div>
                                    </template>
                                    <i class="ri-information-fill"></i>
                                </a-tooltip>
                            </a-radio>
                            <a-radio value="2">样式二
                                <a-tooltip>
                                    <template #title>
                                        <div><img src="@/assets/img/shop-style-2.png" alt="" ></div>
                                    </template>
                                    <i class="ri-information-fill"></i>
                                </a-tooltip>
                            </a-radio>
                            <a-radio value="3">样式三
                                <a-tooltip>
                                    <template #title>
                                        <div><img  src="@/assets/img/shop-style-3.png" alt="" ></div>
                                    </template>
                                    <i class="ri-information-fill"></i>
                                </a-tooltip>
                            </a-radio>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item label="订单超时">
                        <a-input type="number" v-model:value="form.farm_order_exprie" addon-after="分钟"></a-input>
                        <div class="f12 lh16">订单未支付自动取消时间</div>
                    </a-form-item>
                    <a-form-item label="订单自动完成时间">
                        <a-input type="number" v-model:value="form.farm_order_auto_confirm" addon-after="天"></a-input>
                        <div class="f12 lh16">订单从下单到确认收货的时间</div>
                    </a-form-item>
                    <a-form-item label="售后时间">
                        <a-input type="number" v-model:value="form.sale_after_day" addon-after="天"></a-input>
                        <div class="f12 lh16">订单确认收货后，在X天内可进行售后服务，超过X天后不支持进行售后服务</div>
                    </a-form-item>
                    <a-form-item label="门店配送时间段说明">
                        <a-input v-model:value="form.farm_outlet_send_time"></a-input>
                    </a-form-item>
                    <a-form-item :wrapper-col="{offset:3}">
                        <a-button type="primary" @click="submitSave">保存</a-button>
                    </a-form-item>
                </a-form>
            </div>

            <div v-if="active =='shop_set_slide'">
                <page-slide></page-slide>
            </div>
        </com-menu-tab>
        
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import set from "@/api/set.js"
import comMenuTab from '@/components/public/com-menu-tab.vue'
import pageSlide from '@/components/layout/set/page-slide.vue'
import { onBeforeRouteLeave } from 'vue-router'
import rh from '@/router/routerHandle.js'
export default {
    components:{
        comMenuTab,
        pageSlide
    },
    setup() {
        const _d = reactive({
            tabs:[],
            active:localStorage.getItem("_KD_MENU_TAB_"),
            form:{
                shop_style:'1',
                farm_order_exprie:'',
                farm_outlet_send_time:'',
                farm_order_auto_confirm:'',
                sale_after_day:'',
            }
        })
        _d.tabs = rh.getCuurentTabs("shop-set")
        if( !_d.active ) _d.active = _d.tabs[0].key

        getSetData();
        function getSetData(){
            let keys = Object.keys(_d.form)
            set.getFarmSet(keys).then(res=>{
               _d.form = { ...res }
            })
        }

        function submitSave(){
            set.setFarmSet(_d.form)
        }
        onBeforeRouteLeave(() => {
            localStorage.removeItem("_KD_MENU_TAB_")
        })

        return {
            ...toRefs(_d),
            submitSave,
        }
    },
}
</script>
<style lang="scss" scoped>
</style>