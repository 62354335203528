<template>
    <div class="slide">
        <a-button class="mb12" type="primary" v-has="{action:'wxapp_shop_set_slide_edit'}" @click="editSlide(0)">
            <i class="ri-add-line"></i>添加轮播图
        </a-button>
        <a-table row-key="id" :pagination="false" :data-source="info.list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'图片',dataIndex:'cover',slots:{customRender:'cover'}},
            {title:'排序',dataIndex:'rank'},
            {title:'状态',dataIndex:'slide_status',slots:{customRender:'slide_status'}},
            {title:'最后更新时间',dataIndex:'create_time'},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]" class="mt12">
            <template #cover="{record}">
                <a-image style="width:80px;" :src="record.slide_src" class="kd-slide-img-li"/>
            </template>
            <template #slide_status="{record}">
                <a-tag color="#00CC66" v-if="record.slide_status==1">已上架</a-tag>
                <a-tag color="#999" v-else>已下架</a-tag>
            </template>
            <template #action="{record}">
                <a-space>
                    <kd-button type="primary" 
                        title="编辑" 
                        icon="ri-edit-line" 
                        v-has="{action:'wxapp_shop_set_slide_edit'}"
                        @click="editSlide(record)">
                    </kd-button>
                    <kd-button type="danger" 
                        title="删除" 
                        icon="ri-delete-bin-5-line" 
                        v-has="{action:'wxapp_set_slide_del'}"  
                        @click="deleteSlide(record.id)">
                    </kd-button>
                </a-space>
            </template>
        </a-table>
        <div class="pager">
            <a-pagination
                show-size-changer
                :default-current="info.page"
                :total="info.count"
                @showSizeChange="(p,e)=>{getSlide(info.page,e)}"
                @change="(e)=>{getSlide(e,info.limit)}"
            />
        </div>

        <a-modal v-model:visible="showEdit" title="添加轮播图" @ok="saveSlide" @cancel="showEdit = false" width="600px">
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
                <a-form-item label="封面" class="cover-input">
                    <kd-img-select :src="form.slide_src" @success="(url)=>{ form.slide_src = url }"></kd-img-select>
                </a-form-item>
                <a-form-item label="跳转链接">
                    <com-select-link v-model="form.link_param" :not-link="false"></com-select-link>
                </a-form-item>
                <a-form-item label="是否显示">
                    <a-radio-group v-model:value="form.slide_status">
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="排序">
                    <a-input v-model:value="form.rank"></a-input>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import common from '@/api/common.js'
import comSelectLink from '@/components/miniapp/com-select-link.vue'
export default {
    name:'page-slide',
    components:{
        comSelectLink
    },
    setup() {
        const _d = reactive({
            info:{
                list:[],
                page:1,
                limit:10,
                count:0
            },
            showEdit:false,
            form:null
        })
        getSlide(1,_d.info.limit)
        function getSlide(page,limit){
            common.getDataList("getSlideList",{page,limit,type:2}).then(res=>{
                _d.info.list = res.list
                _d.info.count = res.count
            })
        }

        function editSlide(row){
            _d.form = {
                id:row ? row.id :0,
                rank:row ? row.rank : 99,
                slide_src:row ? row.slide_src:'',
                slide_type:row ? row.slide_type:2,
                link_param:row ? row.link_param:'',
                slide_status:row ? row.slide_status:1,

            }
            _d.showEdit = true
        }

        function saveSlide(){
            common.addOrEditSlide(_d.form,()=>{
                getSlide(_d.info.page,_d.info.limit)
                _d.showEdit = false
            })
        }

        const deleteSlide = (id)=> common.deleteDataList(id,17,"确认删除该轮播图吗?").then(()=>{
            getSlide(_d.info.page,_d.info.limit)
        })

        return{
            ...toRefs(_d),
            getSlide,
            deleteSlide,
            saveSlide,
            editSlide
        }
    },
}
</script>